@keyframes blurInOut {
    0%, 100% {
        filter: blur(0);
        opacity: 1;
        scale: 1;
        text-align: center;
    }
    50% {
        filter: blur(10px);
        opacity: 0.7;
        scale: 0.9;
        text-align: center;
    }
}

.blurred {
    animation: blurInOut 1s ease-in-out;
}

.word {
    opacity: 0.2;
    font-size: 24pt; /* Default font size */
}

@media (max-width: 1600px) {
    .word {
        font-size: 14pt;
    }
    
}

@media (max-width: 1200px) {
    .word {
        font-size: 12pt;
    }
}

@media (max-width: 992px) {
    .word {
        font-size: 10pt;
    }
}

@media (max-width: 768px) {
    .word {
        font-size: 8pt;
    }
}

@media (max-width: 576px) {
    .word {
        font-size: 10pt;
    }
}