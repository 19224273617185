.image{
    transition: transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.5);
    cursor: pointer;
    width: 250px;
    border-radius: 44px;
    /* box-shadow: 0px 0px 20px rgb(23, 105, 160); */

}

.image:hover{
    transform: scale(1.3);
    /* box-shadow: 0px 0px 20px rgb(134, 27, 27); */
}

@media (max-width: 768px) {
    .image{
        width: 150px;
        border-radius: 27px;

    }
}
