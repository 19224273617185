input{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    box-sizing: border-box;
    border-radius: 12px;
    /* box-shadow: inset 0 0 10px #00000034; */
    border: none;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    max-width: 100%;
}

textarea{
    width: 100%;
    padding: 12px 20px;
    margin: 8px 0;
    display: inline-block;
    border: none;
    box-sizing: border-box;
    border-radius: 12px;
    /* box-shadow: inset 0 0 10px #00000034; */
    min-height: 150px;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    max-height: 200px;
    resize: none;
}

button[type=support]{
    background-color: #8b2b2b;
    color: rgb(255, 255, 255);
    padding: 14px 20px;
    margin: 8px 0;
    border: none;
    border-radius: 15px;
    cursor: pointer;
}

.container {
    margin-top: 100px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
    box-sizing: border-box;
    overflow-x: hidden;
}
  
.contentWrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0 20px;
    box-sizing: border-box;
}
  
/* For desktop: two-column layout */
@media (min-width: 768px) {
    .contentWrapper {
        flex-direction: row;
        justify-content: space-between;
        gap: 60px;
        align-items: flex-start;
    }
    
    .formSection {
        flex: 0 0 40%;
        position: sticky;
        top: 120px;
    }
    
    .faqSection {
        flex: 0 0 55%;
    }
}
  
/* For mobile: stacked layout */
@media (max-width: 767px) {
    .contentWrapper {
        padding: 0 15px;
    }
    
    .formSection {
        margin-bottom: 40px;
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }
    
    .faqSection {
        width: 100%;
        padding: 15px;
        box-sizing: border-box;
    }
    
    .title {
        font-size: 32px;
    }
    
    .subtitle {
        font-size: 16px;
        line-height: 20px;
    }
    
    .accordionSectionTitle {
        font-size: 20px;
    }
    
    input, textarea {
        padding: 10px 15px;
        font-size: 14px;
    }
    
    .submitButton {
        width: 100%;
        padding: 10px 20px;
    }
}
  
.formSection {
    padding: 20px;
    background-color: rgb(242, 242, 247);
    border: 1px solid rgb(222, 222, 224);
    border-radius: 12px;
    box-sizing: border-box;
    width: 100%;
}

.faqSection {
    padding: 20px;
    box-sizing: border-box;
    width: 100%;
}
  
.title {
    font-size: 42px;
    text-align: center;
    margin-bottom: 10px;
    font-family: 'Inter', sans-serif;
}
  
.subtitle {
    font-size: 18px;
    color: #666;
    margin-bottom: 25px;
    font-weight: normal;
    font-family: 'Inter', sans-serif;
    line-height: 22px;
}
  
.faqTitle {
    font-size: 32px;
    margin-bottom: 30px;
    color: #333;
    font-family: 'Inter', sans-serif;
}
  
.form {
    gap: 20px;
    margin-top: 20px;
    width: 100%;
    box-sizing: border-box;
}
  
.formGroup {
    margin-bottom: 20px;
}
  
.formGroup label {
    display: block;
    margin-bottom: 8px;
    font-size: 16px;
}
  
.input {
    width: 100%;
    padding: 12px;
    border-radius: 10px;
}
  
.submitButton {
    background-color: #000;
    color: #fff;
    padding: 8px 0px;
    border: 2px solid #aaaaaa50;
    border-radius: 12px;
    cursor: pointer;
    margin-top: 15px;
    transition: background-color 0.2s;
    font-weight: 600;
    font-family: 'Inter', sans-serif;
    font-size: 16px;
    width: 200px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
}

.submitButtonText {
    width: 100%;
    text-align: center;
}
  
.submitButton:hover {
    background-color: #333;
}
  
.marginTop {
    margin-top: 20px;
}
  
.accordionSectionTitle {
    font-size: 22px;
    font-weight: bold;
    text-align: left;
    margin-top: 30px;
    margin-bottom: 12px;
    font-family: 'Inter', sans-serif;
    color: #333;
    padding-bottom: 8px;
    margin-left: 10px;

}

/* First accordion title needs less top margin */
.faqSection .accordionSectionTitle:first-child {
    margin-top: 0;
}

/* Dark Mode Styles */
.darkMode {
  background-color: #121212;
  color: #e0e0e0;
}

.darkFormSection {
  background-color: rgba(28, 28, 30);
  color: #e0e0e0;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.darkFaqSection {
  color: #e0e0e0;
}

.darkText {
  color: #e0e0e0;
}

.darkSubtitle {
  color: #b0b0b0;
}

.darkLabel {
  color: #e0e0e0;
}

.darkInput, .darkTextarea {
  background-color: #2d2d2d;
  color: #e0e0e0;
  border: 1px solid #444;
}

.darkInput::placeholder, .darkTextarea::placeholder {
  color: #999;
}

.darkSubmitButton {
  background-color: #fff;
  color: #000;
  border: 1px solid #666;
}

.darkSubmitButton:hover {
  background-color: #e6e6e6;
}

.darkAccordionTitle {
  color: #e0e0e0;
  border-color: #444;
}

.errorMessage {
  color: #d32f2f;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 15px;
  font-size: 14px;
  border-left: 4px solid #d32f2f;
}

.darkMode .errorMessage {
  background-color: rgba(211, 47, 47, 0.1);
  color: #ff6b6b;
}

/* Hide honeypot field from humans but keep it accessible to bots */
.honeypotField {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
  overflow: hidden;
}