.rotateWord {
    height: 100px; /* Adjust as needed */
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    width: 90%;
    font-size: 1em;
    

}

@media screen and (min-width: 700px) {
    .rotateWord {
        width: 100vw;
        font-size: 2em;
        justify-content: center;

    }
    .container {
      display: flex;
      align-items: center;
      flex-direction: column;
      justify-content: left;
      width: 415px;
      height: 1.5em;
    }
    
}

.container{
  height: 1.5em;
}


.active {
  position: absolute;
  display: inline-block;
  opacity: 1;
  transition: opacity 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25), transform 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25), font-weight 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25);
  font-weight: 700;
  filter: blur(0);
  will-change: opacity, transform;
}

.darkMode {
  color: rgb(255, 255, 255);
}

.inactivePlusOneIndex,
.inactiveMinusOneIndex {
  position: absolute;
  display: inline-block;
  opacity: 0.7;
  transition: opacity 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25), transform 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25);
  filter: blur(3px);
  will-change: opacity, transform;
}

.inactivePlusOneIndex {
  transform: translateY(100%);
}

.inactivePlusTwoIndex,
.inactiveMinusTwoIndex {
  position: absolute;
  opacity: 0;
  transition: opacity 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25), transform 0.33s cubic-bezier(0.68, 0.55, 0.27, 1.25);
  filter: blur(3px);
  will-change: opacity, transform;
}

.inactivePlusTwoIndex {
  transform: translateY(200%);
}

.inactiveMinusOneIndex {
  transform: translateY(-100%);
}

.inactiveMinusTwoIndex {
  transform: translateY(-200%);
}

