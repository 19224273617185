/* @import url('https://fonts.googleapis.com/css2?family=Concert+One&family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=M+PLUS+Rounded+1c&display=swap'); */


body{
  background-color: var(--background);
  color: var(--foreground);
  padding: 0;
  margin: 0;
  overflow-x: clip;
  transition: background-color 0.5s ease, color 0.5s ease;
}
html{
  padding: 0;
  margin: 0;
  overflow-x: clip;
}
:root {
  --background: rgb(255, 255, 255);
  --foreground: 222.2 47.4% 11.2%;
  --muted: 210 40% 96.1%;
  --muted-foreground: 215.4 16.3% 46.9%;
  --popover: 0 0% 100%;
  --popover-foreground: 222.2 47.4% 11.2%;
  --border: 214.3 31.8% 91.4%;
  --input: 214.3 31.8% 91.4%;
  --card: 0 0% 100%;
  --card-foreground: 222.2 47.4% 11.2%;
  --primary: 222.2 47.4% 11.2%;
  --primary-foreground: 210 40% 98%;
  --secondary: 210 40% 96.1%;
  --secondary-foreground: 222.2 47.4% 11.2%;
  --accent: 210 40% 96.1%;
  --accent-foreground: 222.2 47.4% 11.2%;
  --destructive: 0 100% 50%;
  --destructive-foreground: 210 40% 98%;
  --ring: 215 20.2% 65.1%;
  --radius: 0.5rem;
}
@media (prefers-color-scheme: dark) {
  :root {
    --background: rgb(0, 0, 0);
    --foreground: 213 31% 91%;
    --muted: 223 47% 11%;
    --muted-foreground: 215.4 16.3% 56.9%;
    --accent: 216 34% 17%;
    --accent-foreground: 210 40% 98%;
    --popover: 224 71% 4%;
    --popover-foreground: 215 20.2% 65.1%;
    --border: 216 34% 17%;
    --input: 216 34% 17%;
    --card: 224 71% 4%;
    --card-foreground: 213 31% 91%;
    --primary: 210 40% 98%;
    --primary-foreground: 222.2 47.4% 1.2%;
    --secondary: 222.2 47.4% 11.2%;
    --secondary-foreground: 210 40% 98%;
    --destructive: 0 63% 31%;
    --destructive-foreground: 210 40% 98%;
    --ring: 216 34% 17%;
  }
}
::selection {
  background-color: #0091ff3d; /* Change this to your desired highlight 0091ff3d color */
  color: #0090FF; /* Optional: Change the text color when highlighted */
}
.background-first-class {
  background-color: #0f0f0f;
  color: #fff;
}
img{
  pointer-events:all;
}
img[type="no-pointer"]{
  pointer-events:none;
}
h1 {
  font-family: "M PLUS Rounded 1c", sans-serif;
  font-weight: bold;
  line-height: normal;
  font-size: 45pt;
  line-height: 90px;
  margin: 0;
}
h1[type="title"] {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  line-height: normal;
  font-size: 60pt;
  line-height: 90px;
  margin: 0;
  font-weight: bold;
  letter-spacing: 3px;
  margin-top: 75px;
}
h2 {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 50pt;
  line-height: 80px;
}
h2[type="title"] {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 50pt;
  line-height: 60px;
  margin: 0;
  font-weight: bold;
  letter-spacing: 4px;
}

* {
  user-select: auto;
  -webkit-user-drag: auto;
  -webkit-app-region: no-drag;
}

img, video {
  user-select: none;
  -webkit-user-drag: none;
}

h3 {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 15pt;
  line-height: 15px;
  margin: 10pt;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

h4 {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 30pt;
  line-height: 30px;
}
p {
  font-family: "Inter", sans-serif;
  font-weight: normal;
  text-align: center;
  font-weight: 200;
  font-size: 15pt;
  line-height: 25px;
  margin: 10pt;
}
@media only screen and (max-width: 800px) {
  h1 {
    font-size: 40pt;
    line-height: 60px;
  }
  h1[type="title"] {
    font-size: 25pt;
    line-height: 50px;
    margin: 10px;
    margin-top: 75px;
  }
  h2 {
    font-size: 30pt;
    line-height: 40px;
  }
  h3 {
    font-size: 15pt;
    line-height: 20px;
  }
  h4 {
    font-size: 20pt;
  }
  h2[type="title"] {
    font-size: 20pt;
  }
}

::-webkit-scrollbar {
  display: none;
} 

 html {
  scrollbar-width: none; 
  -ms-overflow-style: none; 
} 
button{
  -webkit-tap-highlight-color: transparent;
}
a{
  -webkit-tap-highlight-color: transparent;
}

body.dark {
  transition: background-color 0.5s ease, color 0.5s ease;
  --background: #121212;
  --foreground: #fefeff;
}

body {
  transition: background-color 0.5s ease, color 0.5s ease;
  --background: #ffffff;
  --foreground: #000000;
}

body.dark h1, body.dark h2, body.dark h3, body.dark h4, body.dark p {
  transition: color 0.5s ease;
  color: var(--foreground);
}

body.dark .background-first-class {
  transition: background-color 0.5s ease;
  background-color: #1a1a1a;
}

.card{
  transition: background-color 0.5s ease, border-color 0.5s ease, color 0.5s ease;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  text-align: left;
  background-color: #f2f2f7;
  border: 1px solid #e5e5ea;
  border-radius: 20px;
  align-items: center;
  overflow: hidden;
} 

body.dark .card {
  background-color: #161616;
    border: 1px solid #353535;
  color: #fefeff; /* Light text color for dark mode */

}

@media screen and (max-width: 1500px) {
  .card {
      height: 100%;
      flex-direction: column;

  }}


.darkModeToggle{
    -webkit-tap-highlight-color: transparent;
    background-color: rgba(148, 148, 148, 0.5);
    border: 1px solid rgb(119, 119, 119);
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
    backdrop-filter: blur(10px);
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.darkModeToggle:hover{
    background-color: rgb(172, 172, 172);
}

body.dark .darkModeToggle{
    background-color: rgba(56, 56, 56, 0.5);
    border: 1px solid rgb(119, 119, 119);
    color: white;
    position: fixed;
    bottom: 20px;
    right: 20px;
    z-index: 1000;
}

.icon {
  transition: filter 0.2s ease, transform 0.2s ease, opacity 0.2s ease;
  display: flex;
  justify-content: center;
  align-items: center;
}


.blur {
  filter: blur(5px);
  transform: rotate(45deg);
  opacity: 0.5;
}

.toolbar_div{
  background: rgba(244, 244, 244, 0.5);
  border-radius: 27px;
  border-width: 3px;
  width: 500px;
  position: fixed;
  top: 0;
  margin: 10px;
  /* animation: slideDownBounce 0.25s cubic-bezier(0.175, 0.885, 0.32, 1.575); */
  animation: slideDownBounce 0.25s linear;
  z-index: 100;
  border: #f1f1f177 solid 2px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 100%;
  transition: all 0.2s ease-in-out;
  z-index: 100;
}

hr{
  border: 1px solid rgba(0, 0, 0, 0.466);
}

body.dark hr {
  border: 1px solid rgba(51, 51, 51, 0.466);
}

a[type='logo']{
  filter: drop-shadow(0px 4px 2px #00000050);
  height: 100%;
}
@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .toolbar_div {
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2), inset 0px -1px 5px rgba(130, 130, 130, 0.5), inset 0px 1px 5px rgba(130, 130, 130, 0.5) 
  }

  body.dark .toolbar_div {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.2), inset 0px -1px 5px rgba(41, 41, 41, 0.5), inset 0px 1px 5px rgba(34, 34, 34, 0.5)
  }

  
}

.toolbar_div::before {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: 27px;

  background-image: repeating-linear-gradient(90deg,
      rgb(255, 255, 255) 0px,
      rgb(255, 255, 255) 1px,
      transparent 3px,
      transparent 6px);
  mask-image: repeating-linear-gradient(90deg,
  rgb(255, 255, 255) 0px,
  rgb(255, 255, 255) 1px,
      transparent 3px,
      transparent 6px);
  opacity: 0.5; 
  justify-content: center;
}


.toolbar_div::after {
  content: "";
  position: absolute;
  inset: 0;
  z-index: -1;
  border-radius: 27px;

  background-image: repeating-linear-gradient(90deg,
      transparent 0px,
      transparent 3px,
      rgb(207, 207, 207) 4px,
      rgb(211, 211, 211) 5px,
      transparent 6px);
  mask-image: repeating-linear-gradient(90deg,
      transparent 0px,
      transparent 3px,
      rgb(255, 255, 255) 4px,
      rgb(255, 255, 255) 5px,
      transparent 6px);
  opacity: 0.5;
  justify-content: center;
}

body.dark .toolbar_div {
  background: rgba(34, 34, 34, 0.5);
  border: #00000077 solid 2px;
}

body.dark .toolbar_div::after {
  background-image: repeating-linear-gradient(90deg,
      transparent 0px,
      transparent 3px,
          rgb(43, 43, 43) 4px,
      rgb(46, 46, 46) 5px,
      transparent 6px);
  mask-image: repeating-linear-gradient(90deg,
      transparent 0px,
      transparent 3px,
      rgb(255, 255, 255) 4px,
      rgb(255, 255, 255) 5px,
      transparent 6px);
}

body.dark .toolbar_div::before {
  background-image: repeating-linear-gradient(90deg,
      rgb(34, 34, 34) 0px,
      rgb(51, 51, 51) 1px,
      transparent 3px,
      transparent 6px);
  mask-image: repeating-linear-gradient(90deg,
  rgb(0, 0, 0) 0px,
  rgb(0, 0, 0) 1px,
      transparent 3px,
      transparent 6px);
}

@media (max-width: 768px) {
  .toolbar_div{
    width: 100%;
    margin: 0px;
    border-radius: 0px;
    padding: 0px;
    box-shadow: none; 
    display: flex;
    animation: slideDownBounce 1s linear;
    justify-content:space-between;
    background: linear-gradient(to bottom, rgb(255, 255, 255), rgba(255, 255, 255, 0));
    border: rgb(255, 255, 255) solid 2px;
    transition: all 0.2s ease-in-out;
}

  .toolbar_div::before{
      border-radius: 0px;
  }
  .toolbar_div::after{
      border-radius: 0px;
  }
  .toolbar_div::before {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
  
    background-image: repeating-linear-gradient(90deg,
        rgb(255, 255, 255) 0px,
        rgb(255, 255, 255) 1px,
        transparent 3px,
        transparent 6px);
    mask-image: repeating-linear-gradient(90deg,
    rgb(255, 255, 255) 0px,
    rgb(255, 255, 255) 1px,
        transparent 3px,
        transparent 6px);
    opacity: 0.5; 
    justify-content: center;
  }
  
  
  .toolbar_div::after {
    content: "";
    position: absolute;
    inset: 0;
    z-index: -1;
  
    background-image: repeating-linear-gradient(90deg,
        transparent 0px,
        transparent 3px,
        rgb(255, 255, 255) 4px,
        rgb(255, 255, 255) 5px,
        transparent 6px);
    mask-image: repeating-linear-gradient(90deg,
        transparent 0px,
        transparent 3px,
        rgb(255, 255, 255) 4px,
        rgb(255, 255, 255) 5px,
        transparent 6px);
    opacity: 0.5;
    justify-content: center;
  }
}

.header-background{
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, white 90%);
  /* background-color: rgba(255, 255, 255, 0.5); */
  backdrop-filter: blur(100px);
  /* mask-image: linear-gradient(to top, rgba(0, 0, 0, 0), rgba(0, 0, 0, 1) 50%); */
}

body.dark  .header-background{
  background: linear-gradient(to top, rgba(0, 0, 0, 0) 0%, #1a1a1a 90%);
}



.a {
  text-decoration: none;
  color:black;
  font-family: "Inter", sans-serif;
  font-weight:400;
  text-align: center;
  font-size: 16pt;
  line-height: 80px;
  border-right: 2px solid rgb(187, 187, 187);

}

body.dark .a {
  color: rgb(167, 167, 167);
  font-family: "Inter", sans-serif;
  text-align: center;
  font-size: 16pt;
  line-height: 80px;
  border-right: 2px solid rgb(109, 109, 109);
}

@media (max-width: 768px) {
  .a {
    font-size: 10pt;
    font-family: "Inter", sans-serif;
    text-align: center;
    line-height: 80px;
  }

  body.dark .a {
    font-size: 10pt;
    font-family: "Inter", sans-serif;
    text-align: center;
    line-height: 80px;
  }
}


.overlay {
  position: absolute;
  width: 600px;
  height: 300px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: center;
  align-items: center;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2), 0 6px 20px rgba(0, 0, 0, 0.19); */
  border-radius: 15px;
  backdrop-filter: blur(5px);
  background-color: #f2f2f770;
  border: 1px solid #e5e5ea;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.05), 0 3px 40px rgba(0, 0, 0, 0.05);

}

body.dark .overlay {
  backdrop-filter: blur(10px);
  background-color: #16161699;
  border: 1px solid #353535;
  color: #fefeff; /* Light text color for dark mode */
}

@media (max-width: 768px) {
  .overlay {
    width: 100%;
    height: 200px;
    top: 0;
    left: 0;
    transform: translate(0, 0);
    border-radius: 0px;
    box-shadow: none;
    margin-top: 100px;
  }
}


