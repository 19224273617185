.firstClassDetails {
    display: flex;
    flex-direction: row;
    align-items: top;
    justify-content: center;
    color: #8d8d8d;
    gap: 20px;

}

.banner{
    display: flex;
    flex-direction: column;
    align-items: left;
    gap: 20px;
    /* background-color: red; */
    padding: 20px;
}

@media (max-width: 768px) {
    .firstClassDetails {
        flex-direction: column;
        color: #8d8d8d;
        gap: 20px;

    }
}

.details {
    position: relative;
    transition: opacity 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.0), transform 0.2s cubic-bezier(0.175, 0.885, 0.32, 1.07);
}

.details.exiting {
    opacity: 0.4;
    transform: translateX(50%);
    filter: blur(10px);
}

.details.entering {
    opacity: 1;
    transform: translateX(0);
    filter: blur(0);
}

button[type="detail"].selected{
    background-color: #000000;
    color: #e7ff0a;
    border: 2px solid #e6ff0aab;
    box-shadow: 0px 5px 50px #e7ff0a75;
    transition: 0.1s;
}

@media (max-width: 768px) {
    .banner {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 4px;
        width: 95%;
    }
    .firstClassDetails {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        color: #ffffff;
        gap: 20px;
    }
    button[type="detail"] {
        width: 33%;
        height: 30px;
        font-size: 0.75rem;
    }

}
