.container {
    background-color: #161616;
    min-height: 400px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 400px;
    border-radius: 20px;
    border: 1px solid #353535;
}


@media screen and (max-width: 768px) {
    .container {
        width: 90%;
    }
}