.container {
    position: relative;
}

.grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    margin: 100px 100px;
}

@media (max-width: 786px) {
    .grid {
        display: none;
    }
}