.main_div{
    margin-top: 100px;
    border-top: 1px solid #90909044;
    background: linear-gradient(to top, rgb(255, 255, 255), rgb(242, 242, 247));
}

.dark_mode {
    /* Dark mode specific styles for the main container */
    background-color: transparent;
    background: linear-gradient(to top, #121212, rgb(28, 28, 30));

}

.footer_div {
    position: relative;
    padding: 0px;
    margin: 0px;
    margin-top: 100px;
    display: flex;
    flex-direction: column;
}

/* New wrapper for footer content and stamp */
.footer_content_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 0;
}

.footer_bottom_div {
    bottom: 0;
    width: 100%;
    background: transparent;
    text-align: center;
    padding: 20px 0;
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
}

.footer_image{
    display: none;
}

/* Stamp container styles */
.stamp_container {
    flex: 0 0 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 30px;
}

.stamp_wrapper {
    filter: url(#noise);
    opacity: 0.3;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(-25deg);
    width: 250px;
    height: 250px;
}

/* Grid layout styles */
.footer_grid_container {
    flex: 1;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

.footer_grid_section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.footer_heading {
    font-size: 1.1rem;
    font-weight: 600;
    margin-bottom: 16px;
    color: #333;
}

.dark_heading {
    color: #e0e0e0;
}

.footer_link {
    color: #666;
    text-decoration: none;
    margin-bottom: 10px;
    font-size: 0.9rem;
    transition: color 0.2s ease, text-decoration 0.2s ease;
}

.dark_link {
    color: #aaa;
}

.footer_link:hover {
    color: #000;
    text-decoration: underline;
}

.dark_link:hover {
    color: #fff;
    text-decoration: underline;
}

.copyright {
    font-size: 0.9rem;
    color: #888;
    margin: 0;
}

.dark_copyright {
    color: #aaa;
}

@media (max-width: 768px) {
    .footer_image {
        width: 100%;
        height: auto;
        padding: 0px;
        margin: 0px;
        display: block;
    }

    .main_div{
        margin-top: 300px;
        padding: 10px;
    }
    
    /* Responsive grid for mobile - centered 2x2 layout */
    .footer_grid_container {
        grid-template-columns: repeat(2, 1fr);
        gap: 0px;
        justify-content: center;
        width: 100%;
        max-width: 500px;
        margin: 0 auto;
        padding: 0px;
    }
    
    .footer_grid_section {
        align-items: center;
        text-align: center;
    }
    
    /* Reposition stamp below grid on mobile */
    .footer_content_wrapper {
        flex-direction: column;
        align-items: center;
    }
    
    .stamp_container {
        margin-left: 0;
        margin-top: 40px;
        align-self: center;
    }
}

/* Remove the single column layout for small mobile devices 
   to maintain the 2x2 grid even on smaller screens */
@media (max-width: 480px) {
    .footer_grid_container {
        grid-template-columns: repeat(2, 1fr);
        gap: 0px;
    }
}