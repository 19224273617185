.cardContent {
    padding-left: 20px;
    padding-top: 20px;
    max-width: 300px;
    /* border: 1px solid red; */
    display: flex;
    flex-direction: column;
    justify-content: space-between;
} 
.title {
    font-size: 30px;
    font-weight: 600;
    line-height: 1.3;
    text-align: left;
    /* border: 1px solid blue; */
    display: block !important;

}

@media screen and (max-width: 1500px) {
    .card {
        height: 100%;
        flex-direction: column;
    }
    .cardContent {
        padding-left: 0px;
        padding-top: 0px;
        max-width: 90%;
    }
    .title {
        text-align: center;
    }
}

.image{
    height: 100%;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
}

.image img {
    object-fit: contain;
    width: 100%;
    max-height: 500px;
    border-radius: 5px;
}


@media (max-width: 900px) {
    .image img {
        min-height: 200px;
    }
    .imageCenter {
        margin: 15px;
    }
}

.imageBottom {
    align-items: flex-end;
    
}

.imageCenter img {
    align-items: center;
    margin: 15px;
    max-height: 470px;
}

