/* Global dark mode class */
.tableOfContents.darkMode {
  background-color: rgba(28, 28, 30);
  border: 1px solid rgb(44, 44, 47);
}

.tocTitle.darkMode {
  color: #e0e0e0;
}

.tocItem.darkMode:hover {
  background-color: rgba(255, 255, 255, 0.05);
}

.tocItemActive.darkMode {
  background-color: rgba(255, 255, 255, 0.1);
  font-weight: 500;
}
.tocYearItems.darkMode {
  border-left: 2px solid rgba(255, 255, 255, 0.08);
}

.tocVersion.darkMode {
  color: rgb(52, 199, 89);
}

.tocItemTitle.darkMode {
  color: #bbb;
}

.tocYearHeader.darkMode {
  background-color: rgba(255, 255, 255, 0.05);
}

.tocYearHeader.darkMode:hover {
  background-color: rgba(255, 255, 255, 0.08);
}



.tocYear.darkMode {
  color: #aaa;
}

.searchIcon.darkMode {
  color: #888;
}

.tocSearchInput.darkMode {
  background-color: #1e1e1e;
  border-color: #444;
  color: #e0e0e0;
}

.tocSearchInput.darkMode:focus {
  border-color: #666;
}

.clearSearchButton.darkMode {
  color: #888;
}

.clearSearchButton.darkMode:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.noResults.darkMode {
  color: #888;
}

.virtualizedList.darkMode::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.2);
}

.virtualizedList.darkMode .tocItem {
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);
}

.mobileTableOfContents.darkMode {
  background-color: #1e1e1e;
}

.tocItemWrapper.darkMode {
  border-left: 1px solid rgb(58, 58, 60);
}

/* Wrapper for the Table of Contents */
/* .tocWrapper {
  overflow: visible;
  display: flex;
  flex-direction: column;
  border: 3px solid magenta;
  display: flex; 
  position: sticky;


} */

/* Table of Contents Styles */
.tableOfContents {
  position: sticky;
  top: 0;
  max-height: calc(100vh - 200px);
  padding: 6px;
  border-radius: 12px;
  overflow-y: auto;
  z-index: 10;
  backdrop-filter: blur(20px);
  align-self: flex-start;
  width: 300px;
  background-color: rgb(242, 242, 247);
  border: 1px solid rgb(222, 222, 224);
}

.tocTitle {
  font-size: 1.1rem;
  font-weight: 600;
  color: #333;
}

.tocList {
  list-style: none;
  padding: 0;
  margin: 0;
  overflow-y: auto;
  max-height: calc(100vh - 300px);
}

.tocItem {
  display: flex;
  flex-direction: column;
  padding: 8px 10px;
  margin-top: 5px;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.2s ease;
  box-sizing: border-box;

}

.tocItemWrapper {
  display: flex;
  flex-direction: column;
}

.tocItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.tocItemActive {
  background-color: rgba(0, 0, 0, 0.08);
  font-weight: 500;
}

.tocVersion {
  font-size: 0.75rem;
  font-weight: 600;
  color: rgb(52, 199, 89);
  /* margin-bottom: 4px; */
  display: inline-block;
}

.tocItemTitle {
  font-size: 0.85rem;
  color: #333;
  line-height: 1.3;
}

.tocYearGroup {
  /* margin-bottom: 0.5rem; */
  list-style: none;
}

.tocYearHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 10px;
  cursor: pointer;
  border-radius: 6px;
  transition: all 0.2s ease;
  font-weight: 600;
  margin-top: 5px;
  box-sizing: border-box;
  background-color: rgba(0, 0, 0, 0.03);
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.tocYearHeader:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.tocYear {
  font-size: 0.9rem;
  color: #333;
  font-weight: 700;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
}

.tocYearItems {
  padding-left: 0.2rem;
  margin-left: 0.1rem;
  margin-top: 4px;
  /* margin-bottom: 12px; */
  list-style: none;
  border-left: 2px solid rgba(0, 0, 0, 0.08);
}

.tocSearch {
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}

.searchInputWrapper {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 0.5rem;
}

.searchIcon {
  position: absolute;
  left: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #666;
  z-index: 1;
  pointer-events: none;
}

.tocSearchInput {
  width: 100%;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
  border-radius: 8px;
  border: 1px solid #ddd;
  font-size: 0.85rem;
  background-color: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(4px);
  transition: all 0.2s ease;
}

.tocSearchInput:focus {
  outline: none;
  border-color: #999;
}

.clearSearchButton {
  position: absolute;
  right: 8px;
  background: none;
  border: none;
  cursor: pointer;
  color: #666;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 10%;
  transition: background-color 0.2s ease;
}

.clearSearchButton:hover {
  background-color: rgba(0, 0, 0, 0.1);
}

.noResults {
  padding: 1rem 0.5rem;
  color: #666;
  font-style: italic;
  text-align: center;
}

.virtualizedList {
  overflow-x: clip;
  /* scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent; */
  margin: 0;
}

.virtualizedList::-webkit-scrollbar {
  width: 6px;
}

.virtualizedList::-webkit-scrollbar-track {
  background: transparent;
}

.virtualizedList::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 6px;
}

/* Add specific styles for virtualized items */
.virtualizedList .tocItem {
  margin-bottom: 0;
  /* border-bottom: 1px solid rgba(0, 0, 0, 0.05); */
}

.virtualizedList .tocItem:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.virtualizedList .tocItemTitle {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

/* Responsive styles */
@media (max-width: 1024px) {
  .tableOfContents {
    width: 260px;
    padding: 4px;
  }
  
  .tocItem {
    padding: 6px 8px;
  }
  
  .tocYearHeader {
    padding: 6px 8px;
  }
  
  .tocSearch {
    padding: 0 0.3rem;
  }
  
  .tocSearchInput {
    padding: 0.4rem 0.4rem 0.4rem 1.8rem;
    font-size: 0.8rem;
  }
  
  .tocVersion {
    font-size: 0.7rem;
  }
  
  .tocItemTitle {
    font-size: 0.8rem;
  }
}

@media (max-width: 768px) {
  .tableOfContents {
    display: none;
  }
}

/* Mobile table of contents styles */
.mobileTableOfContents {
  display: none;
}

@media (max-width: 768px) {
  .mobileTableOfContents {
    display: block;
    margin-bottom: 2rem;
    padding: 1rem;
    border-radius: 8px;
  }
}

.tocItemContent {
  display: flex;
  flex-direction: column;
  gap: 4px;
  width: 100%;
  position: relative;
}

.tocVersionWrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  width: 100%;
}

.tocCopyLinkButton {
  background: none;
  border: none;
  padding: 2px;
  cursor: pointer;
  color: #666;
  opacity: 0;
  transition: opacity 0.2s ease, color 0.2s ease, background-color 0.2s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
}

.tocItem:hover .tocCopyLinkButton {
  opacity: 1;
}

.tocCopyLinkButton:hover {
  color: #000;
  background-color: rgba(0, 0, 0, 0.05);
}

.darkMode .tocCopyLinkButton:hover {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.1);
}

.tocCopiedTooltip {
  position: absolute;
  background: rgba(0, 0, 0, 0.1);
  color: #000;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 11px;
  right: 24px;
  animation: fadeIn 0.2s ease;
  backdrop-filter: blur(10px);
  white-space: nowrap;
}

.darkMode .tocCopiedTooltip {
  background: rgba(255, 255, 255, 0.1);
  color: #fff;
}

/* Styles for the keyboard shortcut container */
.keyboardShortcutContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  position: absolute;
  right: 6px;
  top: 50%;
  transform: translateY(-50%);
  height: 24px;
  width: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.keyboardShortcutContainer:hover {
  transform: translateY(-50%) scale(1.1);
}

/* Styles for the SVG image */
.slashKeySVG {
  height: 24px;
  width: 24px;
  object-fit: contain;
}

/* Temporary placeholder styles until SVG is ready */
.slashKeyPlaceholder {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  color: #666;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  user-select: none;
}

.darkMode .slashKeyPlaceholder {
  background-color: rgba(255, 255, 255, 0.1);
  color: #aaa;
  border-color: rgba(255, 255, 255, 0.2);
} 