.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #333333;
}

.darkMode {
  background-color: #121212;
  color: #e0e0e0;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem 4rem;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-align: center;
}

.subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
  text-align: center;
}

.darkMode .subtitle {
  color: #aaa;
}

.timeline {
  position: relative;
}

.timelineItem {
  display: flex;
  margin-bottom: 3rem;
  position: relative;
}

.timelineMarker {
  position: relative;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #000000;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.darkMode .circle {
  background-color: rgb(135, 135, 135);
  border: 2px solid #000000;
}

.icon {
  color: white;
  font-size: 1rem;
}

.darkMode .icon {
  color: #000000;
}

.line {
  position: absolute;
  width: 3px;
  height: calc(100% + 3rem );
  background-color: #000000;
  z-index: 1;
}

.darkMode .line {
  background-color: rgb(135, 135, 135);
}

.timelineContent {
  background-color: rgb(242, 242, 247);
  border: 1px solid rgb(222, 222, 224);
  border-radius: 12px;
  padding: 1.5rem;
  flex: 1;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.darkMode .timelineContent {
  background-color: rgb(28, 28, 30);
  border: 1px solid rgb(44, 44, 47);
}

.featureTitle {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  text-align: left;
}

.description {
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.darkMode .description {
  color: #bbb;
}

.details {
  padding-left: 1.5rem;
}

.details li {
  margin-bottom: 0.5rem;
  position: relative;
}

.feedbackSection {
  margin-top: 4rem;
  text-align: center;
  padding: 2rem;
  background-color: rgb(242, 242, 247);
  border: 1px solid rgb(222, 222, 224);
  border-radius: 12px;
}

.darkMode .feedbackSection {
  background-color: rgb(28, 28, 30);
  border: 1px solid rgb(44, 44, 47);
}

.feedbackButton {
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.darkMode .feedbackButton {
  background-color: #fff;
  color: #000;
}

.feedbackButton:hover {
  background-color: #333;
}

.darkMode .feedbackButton:hover {
  background-color: #ddd;
}

/* Platform cards */
.platformsContainer {
  display: flex;
  gap: 2rem;
  margin-bottom: 3rem;
  flex-wrap: wrap;
}

@media (max-width: 768px) {
  .platformsContainer {
    flex-direction: column;
  }
}

.platformCard {
  flex: 1;
  min-width: 250px;
  background-color: rgb(242, 242, 247);
  border: 1px solid rgb(222, 222, 224);
  border-radius: 12px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.platformCard:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
}

.darkMode .platformCard {
  background-color: rgb(28, 28, 30);
  border: 1px solid rgb(44, 44, 47);
}

.darkMode .platformCard:hover {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

.platformIcon {
  margin-bottom: 1.5rem;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background-color: #f5f5f7;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.darkMode .platformIcon {
  background-color: rgba(255, 255, 255, 0.1);
  color: #fff;
}

.platformTitle {
  font-size: 1.5rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.platformDescription {
  color: #555;
  line-height: 1.5;
}

.darkMode .platformDescription {
  color: #bbb;
}

/* Email signup form */
.signupContainer {
  background-color: rgb(242, 242, 247);
  border: 1px solid rgb(222, 222, 224);
  border-radius: 12px;
  padding: 2.5rem;
  text-align: center;
  margin-top: 3rem;
}

.darkMode .signupContainer {
  background-color: rgb(28, 28, 30);
  border: 1px solid rgb(44, 44, 47);
}

.signupTitle {
  font-size: 1.75rem;
  font-weight: 600;
  margin-bottom: 1rem;
}

.signupDescription {
  color: #666;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.darkMode .signupDescription {
  color: #aaa;
}

.emailForm {
  max-width: 500px;
  margin: 0 auto 1.5rem auto;
}

.formGroup {
  margin-bottom: 20px;
  text-align: left;
}

.formGroup label {
  display: block;
  margin-bottom: 8px;
  font-size: 16px;
  color: #333;
}

.darkLabel {
  color: #e0e0e0 !important;
}

.input {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  box-sizing: border-box;
  border-radius: 12px;
  border: none;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  max-width: 100%;
  background-color: #fff;
  color: #333;
}

.darkInput {
  background-color: rgba(255, 255, 255, 0.05);
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.1);
}

.inputError {
  border: 1px solid #ff3b30;
}

.errorMessage {
  color: #ff3b30;
  margin-top: 0.5rem;
  font-size: 0.9rem;
  text-align: left;
  background-color: #ffebee;
  padding: 10px;
  border-radius: 4px;
  border-left: 4px solid #ff3b30;
}

.darkMode .errorMessage {
  background-color: rgba(211, 47, 47, 0.1);
  color: #ff6b6b;
}

.successMessage {
  color: #34c759;
  margin-top: 0.5rem;
  font-size: 0.9rem;
}

/* Hide honeypot field from humans but keep it accessible to bots */
.honeypotField {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  height: 0;
  width: 0;
  z-index: -1;
  overflow: hidden;
}

.submitButton {
  background-color: #000;
  color: #fff;
  padding: 8px 0px;
  border: 2px solid #aaaaaa50;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.2s;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  width: 200px;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
}

.submitButtonText {
  width: 100%;
  text-align: center;
}

.darkSubmitButton {
  background-color: #fff;
  color: #000;
  border-color: rgba(255, 255, 255, 0.2);
}

.submitButton:hover {
  background-color: #333;
}

.darkSubmitButton:hover {
  background-color: #e0e0e0;
}

.privacyNote {
  font-size: 0.85rem;
  color: #888;
  margin-top: 1.5rem;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
}

.darkMode .privacyNote {
  color: #999;
}

.textLink {
  color: #000;
  text-decoration: underline;
  transition: color 0.3s ease;
  display: inline;
  width: auto;
  margin: 0;
  padding: 0;
  border: none;
  background: transparent;
  border-radius: 0;
  box-shadow: none;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

.darkMode .textLink {
  color: #fff;
}

.textLink:hover {
  color: #555;
  background: transparent;
  transform: none;
  box-shadow: none;
}

.darkMode .textLink:hover {
  color: #ccc;
}

/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 1.75rem;
  }
  
  .contentWrapper {
    flex-direction: column;
  }
  
  .signupTitle {
    font-size: 1.5rem;
  }
  
  .platformCard {
    padding: 1.5rem;
  }
  
  .signupContainer {
    padding: 1.5rem;
  }

  .input {
    padding: 10px 15px;
    font-size: 14px;
  }
  
  .submitButton {
    width: 100%;
    padding: 10px 20px;
  }
}

.contentWrapper {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  margin-bottom: 100px;
  /* max-width: 100vh; */
  align-items: flex-start;
  gap: 40px;
  overflow: visible;
}

.mainContent {
  flex: 1;
  max-width: 800px;
  margin: 0 auto;
  overflow: visible;
}

@media (max-width: 1024px) {
  .mainContent {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .mainContent {
    max-width: 100%;
  }
  
  .contentWrapper {
    flex-direction: column;
    overflow: visible;
  }
}

.inputSuccess {
  animation: successPulse 1s ease-in-out;
  border-color: #34c759 !important;
}

.submitSuccess {
  background-color: #34c759 !important;
  color: white !important;
  border-color: #34c759 !important;
}

.darkMode .submitSuccess {
  background-color: #34c759 !important;
  color: white !important;
  border-color: #34c759 !important;
}

@keyframes successPulse {
  0% {
    box-shadow: 0 0 0 0 rgba(52, 199, 89, 0.4);
  }
  70% {
    box-shadow: 0 0 0 10px rgba(52, 199, 89, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(52, 199, 89, 0);
  }
}
