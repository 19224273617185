.ctaButton {
    align-items: center;
    appearance: none;
    background-color: #FCFCFD;
    border-radius: 16px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 3px inset;
    box-sizing: border-box;
    color: #36395A;
    cursor: pointer;
    display: inline-flex;
    height: 50px;
    width: 250px;
    justify-content: center;
    line-height: 1;
    list-style: none;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin: 5px;
}

.ctaButton2{
    background-color: #e4e4f1;
    color: #000000;
    padding: 10px 20px;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    align-items: center;
    appearance: none;
    border-radius: 16px;
    border-width: 0;
    box-shadow: rgba(45, 35, 66, 0.7) 0 2px 4px,rgba(45, 35, 66, 0.4) 0 7px 13px -3px,#8787a0 0 -3px 4px inset;
    box-sizing: border-box;
    color: #36395A;

    cursor: pointer;
    display: inline-flex;
    height: 50px;
    width: 250px;

    justify-content: center;
    line-height: 1;
    list-style: none;
    padding-left: 16px;
    padding-right: 16px;
    position: relative;
    text-align: left;
    text-decoration: none;
    transition: box-shadow .15s,transform .15s;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    white-space: nowrap;
    will-change: box-shadow,transform;
    font-size: 18px;
    margin: 5px;
}

.ctaButton:hover {
    box-shadow: rgba(45, 35, 66, 0.2) 0 4px 8px, rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #D6D6E7 0 -3px 3px inset;
    transform: translateY(-3px) scale(1.02);
}

.ctaButton:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(3px) scale(0.99);
}

.ctaButton2:hover {
    box-shadow: rgba(45, 35, 66, 0.2) 0 4px 8px, rgba(45, 35, 66, 0.2) 0 7px 13px -3px, #8787a0 0 -3px 3px inset;
    transform: translateY(-3px) scale(1.02);
}

.ctaButton2:active {
    box-shadow: #D6D6E7 0 3px 7px inset;
    transform: translateY(3px) scale(0.99);
}


@media (max-width: 768px) {
    .ctaButton {
        width: 150px;
    }
    .ctaButton2 {
        width: 150px;
    }
}