.animate_out {
    animation: slideUp 0.2s forwards;
}
.no_animation {
    animation: none !important;
    transform: translateY(0) !important;
}
@keyframes slideDownBounce {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
}
  
@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-200%);
    }
}

button{
    text-decoration: none;
    color: rgb(0, 0, 0);
    font-family: 'Open Sans', sans-serif;
    padding: 5px 12px;
    border-radius: 12px;
    display: flex;
    margin: 0px;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
    border: none;
    cursor: pointer;
    background-color: transparent;
}

a[type='toolbar']{
    text-decoration: none;
    font-family: 'Open Sans', sans-serif;
    padding: 5px 12px;
    border-radius: 12px;
    display: flex;
    margin: 0px;
    flex-direction: row;
    align-items: center;
    margin-top: auto;
    margin-bottom: auto;
}

h1{
    font-weight: normal;
    line-height: normal;
    font-size: 60pt;
    line-height: 60px;
    margin: 0;
    font-weight: bold;
    letter-spacing: 3px;
    padding-top: 0px;
}
.laurel{
    width: 20px;
    height: 20px;
    object-fit: contain;
    padding: 0px;

}

.not_showing{
  font-size: 0px;
    width: 0px;
    height: 0px;
    opacity: 0; 
    padding: 0px;
    margin: 0px;
    transition: all 0.3s linear;
    /* transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
} 

.signup_button_light:hover {
    background-color: #ffffff00;
    color: #000000;
    border: 2px solid transparent;
    gap: 10px;
    -webkit-backdrop-filter: blur(25px);
    backdrop-filter: blur(25px);
}



.fullScreenCover {
    position: absolute;
    top: -10px;
    left: 0;
    width: 100vw;
    height: 110vh;
    z-index: 100;
    padding: 20px;
    background: rgba(255, 245, 245, 0);
}

.menu{
    background-color: transparent;
    height: 100%;
}
.fullScreenCoverSlideIn {
    animation: slideIn 0.2s forwards linear;
    /* animation: slideIn 0.2s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}

.fullScreenCoverSlideOut {
    animation: slideOut 0.2s forwards linear;
    /* animation: slideOut 0.2s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}
.icon_transition {
  transition: transform 0.3s linear;
  /* transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275); */
}

.rotate_icon {
  transform: rotate(180deg);
  transition: transform 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275), filter 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
@keyframes slideIn {
    from {
        transform: translateX(50%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slideOut {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}
hr{
    height: 30px;
    margin: 15px;
}
@media (max-width: 768px) {
    button[type='text']{
        display: none;
    }
    a[type='text']{
        display: none;
    }
    hr{
        display: none;
        margin: 0px;
        height: 0px;
        border: none;
    }
    .toolbar_div_light{
        width: 100%;
        margin: 0px;
        border-radius: 0px;
        animation: none;
        padding: 0px;
        box-shadow: none; 
        display: flex;
        justify-content:space-between;
        background: linear-gradient(to bottom, rgb(255, 255, 255), #78ffd700);
        border: rgb(255, 255, 255) solid 2px;
        transition: all 0.2s ease-in-out;
    }
    
    .toolbar_div_light::before{
        border-radius: 0px;
    }
    .toolbar_div_light::after{
        border-radius: 0px;
    }


    a[type='logo']{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        left: 0;
        z-index: 100;
        filter: drop-shadow(1px 2px 2px #22222250);
    }
}

.animate_in {
    /* animation: slideDownBounce 0.25s forwards linear; */
    animation: slideDownBounce 0.25s forwards cubic-bezier(0.175, 0.885, 0.32, 1.575);
}

@media screen and (max-width: 768px) {
    .animate_in {
        animation: slideDownBounce 0.25s forwards ease-out;
        /* animation: slideDownBounce 0.25s forwards cubic-bezier(0.175, 0.885, 0.32, 1.575); */
    }
    
}
