.first_class_div{
    background-color: rgb(0, 0, 0);
    padding-top: 50px;
    color: white;
    margin: 0;
    padding: 0;
    z-index: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}
p{
    text-align: center;
    font-size: 1.15rem; 
}

.first_class_div ::selection{
    background-color: #ffc72121;
    color: #ffc721;
}
.content{
    margin-top: 50px;
    

    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    /* background-color: rgb(0, 0, 255); */
}

.first_class_header{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

}

.first_class_header h2{
    font-size: 3rem;
    font-weight: bold;
    margin: 0;
    padding: 20px;
    color: #8d8d8d;
}
.first_class_content_main{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    width: 50%;
    margin: 0 auto;
}

img[type="laurel"]{
    width:40px;

}


.sticker_div {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.sticker_div img {
    width: 200px;
    height: 150px;
    object-fit: contain;
    margin: 0 -30px;
    -webkit-filter: drop-shadow(1px 2px 2px #22222250);
    filter: drop-shadow(1px 2px 2px #22222250);
    margin-bottom: 40px;
}

.sticker_div img:nth-child(1) {
    z-index: 2;
    rotate: 15deg;
}

.sticker_div img:nth-child(2) {
    z-index: 1;
    rotate: -5deg;
}

.sticker_div img:nth-child(3) {
    z-index: 2;
    rotate: 15deg;
}

.sticker_div img:nth-child(4) {
    z-index: 1;
    rotate: -10deg;
}

/* scale all images down on mobile */
button[type="first_class_button"]{
    background-color: white;
    color: black;
    border-radius: 20px;
    padding: 15px 40px;
    margin: 10px;
    border: 2px solid rgb(255, 255, 255);
    position: relative;
    cursor: pointer;
    font-family: 'Roboto Mono', monospace;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    box-shadow: inset 0px -3px 2px rgba(0, 0, 0, 0.2), inset 0px 0px 3px rgba(0, 0, 0, 0.2);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    gap: 0px;
}
img[type="first_class_img"]{
    width: 60%;
    height: 60%;
    object-fit: contain;
    transform: translateY(40px);
    margin-top: -40px;
    z-index: -1;
}
button[type="first_class_button"]:hover {
  background-color: #ffffff00;
  color: #ffffff;
  border: 2px solid rgb(146, 146, 146);
  box-shadow: inset 0px 10px 10px rgba(255, 255, 255, 0.2), inset 0px 0px 10px rgba(255, 255, 255, 0.6);
    gap: 10px;
}
img[type="highlighter"]{
    filter: drop-shadow(0px 0px 10px #FFC92175);
}

.transitionImage {
    display: none; /* Hide by default */
}

@media (max-width: 768px) { /* Adjust the max-width as needed for your mobile breakpoint */
    .transitionImage {
        width: 100%; /* Adjust as needed */
        display: block; /* Show only on mobile */
    }
}
@media (max-width: 1000px) {
    .sticker_div img {
        width: 100px;
        height: 100px;
        margin: 0 -10px;
    }

    .first_class_header h2[type="title"]{
        font-size: 1rem;
    }

    img[type="laurel"]{
        width:15px;
    }
    .first_class_content_main{
        width: 90%;
    }
    p{
        font-size: 1rem;
    }
}

.glowing {
    text-shadow: #FFC921 0px 0px 10px, #FFC921 0px 0px 20px, #FFC921 0px 0px 30px, #FFC921 0px 0px 40px;
}
