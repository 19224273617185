.accordion {
  width: 100%;
  border-radius: 12px;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.1);
  font-family: 'Inter', sans-serif;
  box-sizing: border-box;
}

.accordionItem {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  width: 100%;
  box-sizing: border-box;
}

.accordionItem:last-child {
  border-bottom: none;
}

.accordionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 10px;
  background-color: #fff;
  border: none;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.2s ease;
  box-sizing: border-box;
  overflow-wrap: break-word;
  word-break: break-word;
}

.accordionTitle {
  font-size: 0.9rem;
  font-weight: 500;
  color: #000000;
  flex: 1;
  padding-right: 10px;
}

.accordionIcon {
  font-size: 1.25rem;
  font-weight: 300;
  color: #666;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: center;
  width: 24px;
  height: 24px;
  padding: 2px;
}

.active .accordionIcon {
  color: #000000;
  transform: rotate(180deg);
}

.accordionContent {
  overflow: hidden;
  transition: height 0.2s ease;
}

.accordionBody {
  padding: 0 10px 10px;
  color: #666;
  line-height: 1.5;
  text-align: left;
  font-size: 0.9rem;
  overflow-wrap: break-word;
  word-break: break-word;
}

.accordionHeader:hover .accordionIcon {
  color: #444;
}

/* Dark mode support */
@media (prefers-color-scheme: dark) {
  .accordionHeader {
    background-color: #000000;
  }
  
  .accordionHeader:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
  
  .accordionHeader.active {
    background-color: rgba(0, 0, 0, 0.08);
  }
  
  .accordionTitle {
    color: #e0e0e0;
  }
  
  .accordionIcon {
    color: #999;
    transform-origin: center;
  }
  
  .active .accordionIcon {
    color: #ccc;
    transform: rotate(180deg);
  }
  
  .accordionBody {
    color: #b0b0b0;
    text-align: left;
    font-size: 0.8rem;
  }
  
  .accordionHeader:hover .accordionIcon {
    color: #bbb;
  }
}

/* Mobile optimizations */
@media (max-width: 767px) {
  .accordionTitle {
    font-size: 0.85rem;
  }
  
  .accordionBody {
    font-size: 0.85rem;
    padding: 0 8px 8px;
  }
  
  .accordionHeader {
    padding: 8px 8px;
  }
  
  .accordionIcon {
    width: 20px;
    height: 20px;
    min-width: 20px;
  }
} 