.circle {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0);
  border: 10px double rgb(139, 139, 139);
  /* position: relative; */
  font-family: "Libre Baskerville";
  font-weight: normal;
  line-height: normal;
  font-size: 60pt;
  font-weight: bold;
  color:rgb(139, 139, 139);
  aspect-ratio: 1/1;
}

.circle img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.topHalf, .bottomHalf {
  position: absolute;
  width: 100%;
  text-align: center;
  transform-origin: center;
  font-size: large;
}

.topHalf {
  top: 0;
  transform: translateY(-25%);
  background-color: aquamarine;
}

.bottomHalf {

  transform: translateY(50%);
    background-color: yellow;
}

.letter {
  display: inline-block;
  position: absolute;
  transform-origin: bottom center;
}
