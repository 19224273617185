.container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #ffffff;
  color: #333333;
}

.content *::selection {
  background-color: rgb(52, 199, 89, 0.2); /* Change this to your desired highlight 0091ff3d color */
  color: rgb(52, 199, 89); /* Optional: Change the text color when highlighted */
}

.stickyBar {
  position: -webkit-sticky;
  position: sticky;
  top: 100px;
  height: 300px;
  width: 300px;
  align-self: flex-start;
  margin-left: 20px;
  z-index: 10;
}

.darkMode {
  background-color: #121212;
  color: #e0e0e0;
}

.content {
  max-width: 1200px;
  margin: 0 auto;
  padding: 2rem 1.5rem 4rem;
  width: 100%;
  box-sizing: border-box;
  overflow: visible;
}

.title {
  font-size: 2.5rem;
  font-weight: 700;
  margin-bottom: 0.5rem;
  text-align: center;
}

.subtitle {
  font-size: 1.2rem;
  color: #666;
  margin-bottom: 3rem;
  text-align: center;
}

.darkMode .subtitle {
  color: #aaa;
}

.timeline {
  position: relative;
}

.timelineItem {
  display: flex;
  margin-bottom: 3rem;
  position: relative;
}

.timelineMarker {
  position: relative;
  min-width: 60px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.circle {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background-color: #000000;
  border: 2px solid #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
}

.darkMode .circle {
  background-color: rgb(135, 135, 135);
  border: 2px solid #000000;
}

.checkIcon {
  color: white;
  font-size: 1rem;
}

.darkMode .checkIcon {
  color: #000000;
}

.line {
  position: absolute;
  width: 3px;
  height: calc(100% + 3rem );
  background-color: #000000;
  z-index: 1;
}

.darkMode .line {
  background-color: rgb(135, 135, 135);
}

.timelineContent {
  background-color: rgb(242, 242, 247);
  border: 1px solid rgb(222, 222, 224);
  border-radius: 12px;
  padding: 1.5rem;
  flex: 1;
  text-align: left;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.darkMode .timelineContent {
  background-color: rgb(28, 28, 30);
  border: 1px solid rgb(44, 44, 47);
}

.versionHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
}

.version {
  font-weight: 600;
  color: rgb(52, 199, 89);
  padding: 0.25rem 0.75rem;
  background-color: rgb(52, 199, 89, 0.2);
  border-radius: 20px;
  font-size: 0.9rem;
}

.darkMode .version {
  background-color: rgb(52, 199, 89, 0.2);
}

.date {
  color: #888;
  font-size: 0.9rem;
}

.darkMode .date {
  color: #aaa;
}

.changeTitle {
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  font-weight: 600;
  text-align: left;
}

.description {
  color: #555;
  margin-bottom: 1rem;
  line-height: 1.5;
  font-weight: 600;
}

.darkMode .description {
  color: #bbb;
}

.details {
  padding-left: 1.5rem;
}

.details li {
  margin-bottom: 0.5rem;
  position: relative;
}

/* Responsive styles */
@media (max-width: 768px) {
  .title {
    font-size: 1.75rem;
  }
  
  .contentWrapper {
    flex-direction: column;
  }
  
  .timelineMarker {
    min-width: 40px;
  }
  
  .circle {
    width: 25px;
    height: 25px;
  }
  
  .line {
    top: 0px;
  }
  
  .changeTitle {
    font-size: 1.25rem;
  }
  
  .versionHeader {
    flex-direction: column;
    align-items: flex-start;
  }
  
  .date {
    margin-top: 0.25rem;
  }
}

.contentWrapper {
  display: flex;
  position: relative;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;
  align-items: flex-start;
  gap: 40px;
  overflow: visible; /* Added to ensure sticky elements work properly */
}

.mainContent {
  flex: 1;
  max-width: 800px;
  overflow: visible;
}

@media (max-width: 1024px) {
  .mainContent {
    max-width: 700px;
  }
}

@media (max-width: 768px) {
  .mainContent {
    max-width: 100%;
  }
  
  .contentWrapper {
    flex-direction: column;
    overflow: visible;
  }
}

/* Add styles for mobile table of contents if needed in the future */
.mobileTableOfContents {
  display: none;
}

.versionWithLink {
  display: flex;
  align-items: center;
  gap: 6px;
}
.submitButton {
  background-color: #000;
  color: #fff;
  padding: 8px 16px;
  border: 2px solid #aaaaaa50;
  border-radius: 12px;
  cursor: pointer;
  margin-top: 15px;
  transition: background-color 0.2s;
  font-weight: 600;
  font-family: 'Inter', sans-serif;
  font-size: 16px;
  text-align: center;
  margin-left: auto;
}

.submitButtonText {
  width: 100%;
  text-align: center;
}

.submitButton:hover {
  background-color: #333;
}

.darkSubmitButton {
  background-color: #fff;
  color: #000;
  border: 1px solid #666;
}

.darkSubmitButton:hover {
  background-color: #e6e6e6;
}


.copyLinkButton {
  background: none;
  border: none;
  padding: 4px;
  cursor: pointer;
  color: #666;
  opacity: 0;
  transition: opacity 0.2s ease, color 0.2s ease, background-color 0.2s ease;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timelineItem:hover .copyLinkButton {
  opacity: 1;
}

.copyLinkButton:hover {
  color: #000;
  background-color: #f0f0f0;
}

.darkMode .copyLinkButton:hover {
  color: #fff;
  background-color: #333;
}

.copiedTooltip {
  position: absolute;
  background: #3333331a;
  color: rgb(0, 0, 0);
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  margin-left: 28px;
  animation: fadeIn 0.2s ease;
  backdrop-filter: blur(10px);

}

.darkMode .copiedTooltip {
  background: #ffffff1a;
  color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(4px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}



