.main{
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  gap: 20px;
}

.content {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: auto;
  gap: 20px;
  margin: 40px;
}

@media screen and (max-width: 1200px) {
  .content {
    grid-template-columns: repeat(1, 1fr);
    gap: 20px;
    margin: 10px;
  }
}

.images {
  display: flex;
  justify-content: space-around; /* Adjusts the spacing between images */
  align-items: center; /* Aligns images vertically */
  flex-wrap: wrap; /* Allows images to wrap onto the next line if needed */
  margin-top: 20px; /* Adds space between the carousel and the images */
  position: relative; /* Ensure this is positioned */
  z-index: -1; /* Ensure this is behind the carousel */
}

.images img {
  width: auto;
  height: 300px; 
  margin: 10px;
}
