.feature{
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 0;
    z-index: 2;
}
.para{
    font-size: 1.5rem;
    line-height: 1.5;
    margin: 0;
    width: 30%;
    min-width: 500px;
    text-align: left;
}
img[type="feature_img"]{
    width: 40%;
    height: 40%;
    object-fit: contain;
}
@media (max-width: 1000px) {
    .feature {
        flex-direction: column;
        padding: 1rem;

        
    }
    .para {
        width: 90%;
        margin-bottom: 3rem;
        text-align: left;
        margin-right: 0rem;
        font-size: 1rem;  
        min-width: 0px;
    }
    img[type="feature_img"]{
        width: 100%;
        height: 100%;
        object-fit: contain;
    }
}


.heroImage {
    width: 600px;
    height: auto;
    margin-top: 100px;
}

