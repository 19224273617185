.lastUpdateContainer {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid rgb(128, 128, 128, 0.5);
    padding:  3px 3px 3px 9px;
    border-radius: 15px;
    flex-direction: row;
    text-decoration: none;
}

.lastUpdateContainer  *::selection {
  background-color: rgb(52, 199, 89, 0.2); /* Change this to your desired highlight 0091ff3d color */
  color: rgb(52, 199, 89); /* Optional: Change the text color when highlighted */
}

a{
    text-decoration: none;
    padding: 0;
    margin: 0;
    align-items: center;
    display: flex;
}

.pulseCircle {
    width: 5px;
    height: 5px;
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(52, 199, 89, 1);
    animation: pulse 3s infinite forwards;
}

@keyframes pulse {
    0% {
        background: rgba(52, 199, 89, 1);
        box-shadow: 0 0 0 0 rgba(52, 199, 89, 0.7);
    }
    50% {
        background: rgb(52, 199, 89);

        box-shadow: 0 0 0 8px rgba(50, 205, 50, 0);
        filter: blur(2px);
    }
    100% {
        background: rgba(52, 199, 89, 1);

        box-shadow: 0 0 0 0 rgba(52, 199, 89, 0.0);
    }
}

.lastUpdateText {
    margin: 0;
    font-size: 12px;
    color: #666;
    line-height: 1;
    gap:10px
}

.version {
    padding: 2px 0px 2px 8px;
    gap: 0;
    background-color: rgba(52, 199, 89, 0.207);
    border-radius: 20px;
    font-weight: 700;
    color: rgba(52, 199, 89, 1);
    font-size: 12px;
    line-height: 1;
    display: inline-flex;
    align-items: center;
}
